import { defineStore, type StoreActions, type StoreGetters, type StoreState } from 'pinia'


export interface BoxStore {
	state:BoxState;
	getters:Getters;
	actions:Actions;
}

export interface BoxItem {id:string, title:string, header:string, slideshow:string[]}

export interface BoxState {
	items: BoxItem[];
}

interface Getters {}

interface Actions {}

export const boxStore = defineStore("boxes", {
	state: ():BoxState => ({
		items:[
			{
				id:"flatbox",
				title:"Flatbox",
				header:"Sequential discovery puzzle box",
				slideshow:["1.jpg","2.jpg","3.jpg","4.jpg"],
			},
			{
				id:"octopuzz",
				title:"Octopuzz",
				header:"Escape room puzzle box",
				slideshow:["01.jpg","02.jpg","03.jpg","04.jpg","05.jpg","06.jpg","07.jpg","08.jpg"],
			},
			{
				id:"irisbox",
				title:"Iris box",
				header:"Matryoshka of the puzzle boxes",
				slideshow:["1.jpg","2.jpg","3.jpg","4.jpg"],
			},
			{
				id:"theartifact",
				title:"The Artifact",
				header:"Immersive puzzlebox",
				slideshow:["1.jpg","2.jpg","3.jpg","4.jpg"],
			},
		]
	}),

	getters: {
	},

	actions: {
	} as Actions,
});