import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
	history: createWebHistory("/"),
	routes: [
		{
			path: '/',
			name: 'home',
			component: HomeView
		},
		{
			path: '/info/:id',
			name: 'info',
			props: true,
			component: () => import('../views/BoxInfoView.vue')
		},
		{
			path: '/guide/:id',
			name: 'guide',
			props: true,
			component: () => import('../views/SolutionView.vue')
		},
		{
			path: '/checklist/:id',
			name: 'checklist',
			props: true,
			component: () => import('../views/CheckListView.vue')
		},
		{
			path: '/register/:code',
			name: 'register',
			props: true,
			component: () => import('../views/RegisterView.vue')
		},
		{
			path: '/reset/:id',
			name: 'reset',
			props: true,
			component: () => import('../views/ResetView.vue')
		},
		{
			path: '/brettreset',
			name: 'brettreset',
			props: true,
			component: () => import('../views/BrettResetView.vue')
		},
		{
			path: '/codegen',
			name: 'codegen',
			props: true,
			component: () => import('../views/CodeGenView.vue')
		},
		{
			path: "/:path(.*)",
			redirect:to => {
				const path = to.params?.path as string ?? "";
				if(path == "octopuzz" || path == "flatbox" || path == "irisbox" || path == "theartifact") {
					return {name:"info", params:{id:path}};
				}
				if(/[1234567890abcdefghijklmnopqrstuvwxyz]{8}/i.test(path)) {
					return {name:"register", params:{code:to.params.path}}
				}else{
					return {name:"home"};
				}
			},
		},
	]
})

export default router
