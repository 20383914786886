import { defineStore } from 'pinia'

export const componentsStore = defineStore("components",{
	state: () => ({
		_alert: "",
		_gallery:[] as string[],
	}),
	getters: {
		alert: (state) => state._alert,
		gallery: (state) => state._gallery,
		// doubleCount: (state) => state.counter * 2
	},
	actions: {
		openAlert(message:string) { this._alert = message; },
		openGallery(images:string[]) { this._gallery = images; },
		closeGallery() { this._gallery = []; },
	}
})
