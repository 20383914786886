<template>
	<div :class="classes">
		<router-link class="holder" :to="to" @click.capture.stop="onClick">
			<div class="image" ref="image" :style="imagesStyles" @mouseenter="hoverImg = true" @mouseleave="hoverImg = false">
				<img :src="plainURL" :alt="boxID" class="plain" :style="plainImgStyles" ref="plainImg">
				<img :src="wireframeURL" :alt="boxID" class="wireframe" :style="wireImgStyles">
			</div>
			<div class="title" ref="title">{{title}}</div>
		</router-link>
	</div>
</template>

<script lang="ts">
import Utils from '@/utils/Utils';
import gsap from 'gsap/all';
import { Options, Vue } from 'vue-class-component';

@Options({
	props:{
		boxID:String,
		title:String,
		to:Object,
	},
	components:{},
	emits:["close"],
})
export default class HomeBox extends Vue {

	public to!:any;
	public boxID!:string;
	public title!:string;
	public hoverImg:boolean = false;
	public closing:boolean = false;

	private mouseMoveHandler!:(e:MouseEvent|TouchEvent)=>void;
	private disposed:boolean = false;
	private mousePercent:{x:number, y:number} = {x:0, y:0};
	private mousePercent_eased:{x:number, y:number} = {x:0, y:0};
	private mouseImageX:number = -.2;
	private mouseImageX_eased:number = 0;
	private hoverSizePercent:number = .2;

	public get classes():string[] {
		let res = ["homebox"];
		res.push(this.boxID);
		if(this.hoverImg) res.push("hover")
		return res;
	}

	public get plainURL():string {
		return new URL(`../assets/images/boxes/${this.boxID}.png`, import.meta.url).href;
	}

	public get wireframeURL():string {
		return new URL(`../assets/images/boxes/${this.boxID}_wireframe.png`, import.meta.url).href;
	}

	public get plainImgStyles():any {
		const percent = this.mouseImageX_eased * 100;
		return {
			// "clip-path": `polygon(0 0, ${percent}% 0, ${percent}% 100%, 0 100%)`,
		}
	}
	public get wireImgStyles():any {
		const size = this.hoverSizePercent * 100;
		const percent = this.mouseImageX_eased * 100 - size/2;
		return {
			"clip-path": `polygon(${percent}% 0, ${percent + size}% 0, ${percent + size}% 100%, ${percent}% 100%)`,
		}
	}

	public get imagesStyles():any {
		const rx = (this.mousePercent_eased.y) * 10;
		const ry = (this.mousePercent_eased.x-.5) * 2;
		return {
			transform:`rotateX(${rx}deg) rotateZ(${ry}deg)`
		}
	}

	public mounted():void {
		this.mouseMoveHandler = (e:MouseEvent|TouchEvent) => this.onMouseMove(e);
		document.addEventListener("mousemove", this.mouseMoveHandler);
		document.addEventListener("touchmove", this.mouseMoveHandler);

		this.renderFrame();
	}

	public beforeUnmount():void {
		this.disposed = true;
		document.removeEventListener("mousemove", this.mouseMoveHandler);
		document.removeEventListener("touchmove", this.mouseMoveHandler);
	}

	public async onClick(e:MouseEvent):Promise<void> {
		this.closing = true;
		e.stopPropagation();
		e.preventDefault();
		this.onMouseMove(e);

		this.mouseImageX_eased = this.mouseImageX;
		
		gsap.to(this, {hoverSizePercent:2, duration:1, ease:"sine.inOut", delay:.15});
		gsap.to(this.$refs.title as HTMLDivElement, {scale:1.35, duration:2, filter:"blur(10px)", ease:"sine.inOut", opacity:0});
		
		await Utils.promisedTimeout(500);
		this.$emit("close", this.to);

	}

	public open():void {
		gsap.from(this.$refs.title as HTMLDivElement, {duration:1, y:50, delay:1, opacity:0})
	}

	public openFromLeft():void {
		gsap.killTweensOf(this.$refs.title as HTMLDivElement);
		gsap.fromTo(this.$refs.title as HTMLDivElement,
			{x:"3em", opacity:0},
			{duration:1, delay:.75, x:0, opacity:1, ease:"sine.put", clearProps:"all"});
	}

	public openFromRight():void {
		gsap.killTweensOf(this.$refs.title as HTMLDivElement);
		gsap.fromTo(this.$refs.title as HTMLDivElement,
			{x:"-3em", opacity:0},
			{duration:1, delay:.75, x:0, opacity:1, ease:"sine.put", clearProps:"all"});
	}

	public closeToLeft():void {
		gsap.killTweensOf(this.$refs.title as HTMLDivElement);
		gsap.to(this.$refs.title as HTMLDivElement, {duration:1, x:"3em", opacity:0, ease:"sine.in", clearProps:"all"});
	}

	public closeToRight():void {
		gsap.killTweensOf(this.$refs.title as HTMLDivElement);
		gsap.to(this.$refs.title as HTMLDivElement, {duration:1, x:"-3em", opacity:0, ease:"sine.in", clearProps:"all"});
	}

	private onMouseMove(e:MouseEvent|TouchEvent):void {
		const bounds = (this.$refs.plainImg as HTMLDivElement).getBoundingClientRect();
		
		//Element out of screen, ignore it
		if(bounds.x < 0 || bounds.x > document.body.clientWidth) return;

		const pos = {x:0, y:0};
		let onMobile = false;
		if(e instanceof MouseEvent) {
			pos.x = e.clientX;
			pos.y = e.clientY;
		}else{
			pos.x = e.touches[0].clientX;
			pos.y = e.touches[0].clientY;
			onMobile = true;
		}
		this.mousePercent.x = pos.x / document.body.clientWidth;
		this.mousePercent.y = pos.y / document.body.clientHeight;

		const mouseImageY = (pos.y - bounds.y)/bounds.height; 
		this.mouseImageX = (pos.x - bounds.x)/bounds.width;

		if(onMobile) {
			//If on mobile, check if finger is hover the image
			//to show the texture image
			this.hoverImg = mouseImageY > 0 && mouseImageY < 1 && this.mouseImageX > 0 && this.mouseImageX < 1;
		}
	}

	private renderFrame():void {
		if(this.disposed || this.closing) return;
		requestAnimationFrame(()=>this.renderFrame());

		const ease = .1;
		this.mouseImageX_eased += (this.mouseImageX - this.mouseImageX_eased) * ease;
		this.mousePercent_eased.x += (this.mousePercent.x - this.mousePercent_eased.x) * ease;
		this.mousePercent_eased.y += (this.mousePercent.y - this.mousePercent_eased.y) * ease;
		
		if(Math.abs(this.mouseImageX_eased - this.mouseImageX) < .001) this.mouseImageX_eased = this.mouseImageX;
		if(Math.abs(this.mousePercent_eased.x - this.mousePercent.x) < .005) this.mousePercent_eased.x = this.mousePercent.x;
		if(Math.abs(this.mousePercent_eased.y - this.mousePercent.y) < .005) this.mousePercent_eased.y = this.mousePercent.y;
	}

}
</script>

<style scoped lang="less">
.homebox{
	position: relative;

	// &.octopuzz>a>.title{
	// 	top: 40%;
	// }

	// &.flatbox>a>.title{
	// 	top: 45%;
	// }

	&.hover {
		.holder {
			.title {
				// opacity: 0;
				backdrop-filter: blur(2px);
				// background-color: rgba(0, 0, 0, .15);
			}
			.image {
				.plain {
					opacity: 1;
					filter: saturate(100%) contrast(100%);
				}
			}
		}
	}

	.holder {
		text-decoration: none;
		margin: auto;
		display: block;
		width: calc(100% - 15em);
		max-width: 800px;
		// overflow-y: auto;
		// overflow-x: hidden;
		// height: 100%;

		.title {
			.center();
			top: 40%;
			position: absolute;
			
			text-align: center;
			color: @mainColor_normal;
			pointer-events: none;
			font-family: "Megrim";
			font-size: 2.5em;
			padding: .25em;
			
			background-color: rgba(0, 0, 0, .75);
			backdrop-filter: blur(3px);
			transition: backdrop-filter 1s, background-color 1s;

			// z-index: 1;
		}

		.image {
			position: relative;
			user-select: none;

			img {
				width: 100%;
				max-height: 50vh;
				object-fit: contain;
			}

			@p: 100%;
			.plain {
				transition: opacity 1s, filter 1s;
				filter: saturate(0%) contrast(150%);
			}
			.wireframe {
				position: absolute;
				top: 0;
				left: 0;
				// mix-blend-mode: lighten;
				clip-path: polygon(@p 0, 100% 0, 100% 100%, @p 100%);
			}
		}
	}
}
@media only screen and (max-width: 480px) {
.homebox{
	.holder {
		width: calc(100% - 1em);
		.title {
			font-size: 2.5em;
		}

		.image {
			img {
				max-height: 40vh;
			}
		}
	}
}
}
</style>