<template>
	<div class="pictureviewer" v-if="imageList?.length > 0" @click="onClick()">
		<div class="holder" ref="holder">
			<img :src="pictureURL" class="picture" @click.stop="" ref="image" @load="onImageLoaded()">

			<div class="navigation" ref="nav">
				<button class="prev" @click="prev($event)">
					<img src="@/assets/icons/arrow_prev.svg" alt="prev">
				</button>
				<button class="next" @click="next($event)">
					<img src="@/assets/icons/arrow_next.svg" alt="next">
				</button>
			</div>
		</div>

			<button class="closeBt" @click="close()">
				<img src="@/assets/icons/close.svg" alt="next">
			</button>
	</div>
</template>

<script lang="ts">
import { componentsStore } from '@/stores/components';
import { watch } from '@vue/runtime-core';
import gsap from 'gsap/all';
import { Options, Vue } from 'vue-class-component';

@Options({
	props:{},
	components:{}
})
export default class PictureViewer extends Vue {

	public imageIndex:number = 0;

	private onLoadAnimation:(()=>void) | null = null;

	public get imageList():string[] {
		return componentsStore().gallery;
	}

	public get pictureURL():string {
		return this.imageList[ this.imageIndex ];
	}

	public mounted():void {
		watch(()=>componentsStore().gallery, async ()=>{
			this.imageIndex = 0;
			if(this.imageList.length > 0) {
				await this.$nextTick();
				this.open();
			}
		});
	}

	public open():void {
		gsap.fromTo(this.$el as HTMLDivElement, { opacity:0 }, { opacity:1, duration:1, clearProps:"all", ease:"sine.out" });
		gsap.fromTo(this.$refs.holder as HTMLDivElement, { y:50 }, { y:0, duration:.5, clearProps:"all", ease:"sine.out" });
		gsap.from(this.$refs.nav as HTMLDivElement, { opacity:0, width:"80vw", duration:.5, delay:.5, clearProps:"all", ease:"sine.out" });
	}

	public close():void {
		gsap.to(this.$el as HTMLDivElement, { opacity:0, duration:.5, ease:"sine.inOut", onComplete:()=> {
			componentsStore().closeGallery();
		} });
	}

	public prev(e:MouseEvent):void {
		e.stopPropagation();
		const holder = this.$refs.image as HTMLImageElement;
		gsap.to(holder, { x:25, opacity:0, duration:.25, ease:"sine.in", onComplete:()=> {
			this.imageIndex --;
			if(this.imageIndex == -1) this.imageIndex = this.imageList.length - 1;

			this.onLoadAnimation = ()=> {
				gsap.fromTo(holder, {x:-25}, { x:0, opacity:1, duration:.25, clearProps:"all", ease:"sine.out" });
			}
		} });
	}

	public next(e:MouseEvent):void {
		e.stopPropagation();
		const holder = this.$refs.image as HTMLImageElement;
		gsap.to(holder, { x:-25, opacity:0, duration:.25, ease:"sine.in", onComplete:()=> {
			this.imageIndex ++;
			this.imageIndex = this.imageIndex % this.imageList.length;
			if(this.imageIndex == -1) this.imageIndex = this.imageList.length - 1;
			this.onLoadAnimation = ()=> {
				gsap.fromTo(holder, {x:25}, { x:0, opacity:1, duration:.25, clearProps:"all", ease:"sine.out" });
			}
		} });
	}

	public onImageLoaded():void {
		if(this.onLoadAnimation) {
			this.onLoadAnimation();
			this.onLoadAnimation = null;
		}
	}

	public onClick():void {
		this.close()
	}

}
</script>

<style scoped lang="less">
.pictureviewer{
	background: rgba(0, 0, 0, .8);
	backdrop-filter: blur(10px);
	width: 100vw;
	height: 100vh;
	position: fixed;
	top:0;
	left:0;
	z-index: 10;

	.closeBt {
		position: fixed;
		top: 1em;
		right: 1em;
		width: 2em;
		height: 2em;
		background: none;
		transform-origin: center center;
		transition: transform .3s;
		padding: 0;
		margin: 0;

		&:hover {
			transform: rotate(90deg)
		}
	}

	.holder {
		.center();
		position: absolute;
		.picture {
			max-height: calc(100vh - 10em);
			max-width: calc(100vw - 2em);
			object-fit: contain;
		}

		.navigation {
			.center();
			top: 50%;
			// overflow: hidden;
			position: absolute;
			width: 100vw;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			pointer-events: none;

			button {
				background: none;
				transition: all 1s;
				padding: 2em;
				pointer-events: all;
				img {
					width: 2em;
				}
				&.prev {
					&:hover {
						margin-left: -1em;
						padding-right: 3em;
					}
				}
				&.next {
					&:hover {
						margin-right: -1em;
						padding-left: 3em;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 480px) {
.pictureviewer{
	.holder {
		.navigation {
			position: unset;
			transform: unset;
			top: unset;
			left: unset;
			width: 40%;
			margin: auto;

			&.noPrev {
				justify-content: space-between;
			}
			
			button {
				padding: .5em;
				img {
					width: 1em;
				}

				&.prev:hover,
				&.next:hover {
					margin-right: unset;
					margin-left: unset;
				}
			}
		}
	}
}
}
</style>