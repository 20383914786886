import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import './less/index.less';
import Config from './utils/Config';
import { boxStore } from './stores/boxes';

Config.init();

/**
 * Include an image from the asset folder
 */
const image = (path:string):string => {
	return new URL(`/src_front/assets/${path}`, import.meta.url).href;
}

const app = createApp(App)

.provide("$image", image)
app.use(createPinia())
app.use(router)
app.config.globalProperties.$image = image;
app.config.globalProperties.$boxStore = boxStore();

app.mount('#app');