<template>
	<div class="homeview">
		<header ref="header">
			<h1>DURSS BOXES</h1>
			<span class="subtitle">handcrafted wooden puzzle boxes</span>
		</header>

		<div class="boxes">
			<div class="list">
				<HomeBox class="box"
					ref="box"
					@close="onClose"
					v-for="b in boxList"
					:key="b.id"
					:boxID="b.id"
					:title="b.title"
					:to="{name:'info', params:{id:b.id}}"
				/>
			</div>

			<div :class="navClasses" ref="nav">
				<button class="prev" @click="prev()">
					<img src="@/assets/icons/arrow_prev.svg" alt="prev">
				</button>
				<button class="next" @click="next()">
					<img src="@/assets/icons/arrow_next.svg" alt="next">
				</button>
			</div>
		</div>

	</div>
</template>

<script lang="ts">
import Button from '@/components/Button.vue';
import HomeBox from '@/components/HomeBox.vue';
import type { ITransitionnableView } from '@/utils/ITransitionnableView';
import Utils from '@/utils/Utils';
import gsap from 'gsap/all';
import { Options, Vue } from 'vue-class-component';

@Options({
	props:{},
	components:{
		Button,
		HomeBox,
	}
})
export default class HomeView extends Vue implements ITransitionnableView {

	public currentIndex:number = 0;

	public boxList:{id:string, title:string}[] = [];

	public get navClasses():string[] {
		let res = ["navigation"]
		if(this.currentIndex == 0) res.push("noPrev");
		return res;
	}

	public beforeMount():void {
		this.boxList = this.$boxStore.items;
	}

	public mounted():void {
		let boxes = this.$refs.box as HomeBox[];
		gsap.from(this.$refs.header as HTMLDivElement, {duration:2, opacity:0, clearProps:"all"});
		gsap.from(boxes.map(v => v.$el), {duration:2, opacity:0, delay:.5, y:100, clearProps:"all"});
		gsap.from(this.$refs.nav as HTMLDivElement, {duration:2, opacity:0, delay:1, width:"100vw", maxWidth:"120vw", clearProps:"all"});

		for (let i = 0; i < boxes.length; i++) {
			boxes[i].open();
		}
	}

	public transitionOpen(done:()=>void):void {
		done();
	}

	public async transitionLeave(done:()=>void):Promise<void> {
		await Utils.promisedTimeout(2000);
		done();
	}

	public async onClose(to:any):Promise<void> {
		let boxes = this.$refs.box as HomeBox[];
		gsap.to(this.$refs.header as HTMLDivElement, {duration:.5, opacity:0, delay:.5});
		gsap.to(boxes.map(v => v.$el), {duration:1, opacity:0, scale:.8, ease:"sine.in"});
		gsap.to(this.$refs.nav as HTMLDivElement, {
			duration:1.1, opacity:0, width:"120vw", maxWidth:"120vw",
			onComplete:() => {
				this.$router.push(to);
			}
		});
	}

	public prev():void {
		this.slide(-1);
	}

	public next():void {
		this.slide(1);
	}

	private slide(direction:number):void {
		const prevValue = this.currentIndex;

		this.currentIndex += direction;
		if(this.currentIndex < 0) this.currentIndex = this.boxList.length-1;
		if(this.currentIndex > this.boxList.length-1) this.currentIndex = 0;
		direction = prevValue - this.currentIndex;//override direction in case we're looping back

		let boxes = this.$refs.box as HomeBox[];
		const vw:number = boxes[0].$el.getBoundingClientRect().width;
		for (let i = 0; i < boxes.length; i++) {
			const b = boxes[i];
			if(this.currentIndex == i) {
				if(direction == 1) b.openFromRight();
				else b.openFromLeft();
			}else{
				if(direction == 1) b.closeToLeft();
				else b.closeToRight();
			}
			gsap.killTweensOf(b.$el);
			gsap.to(b.$el, {duration:1.5, x:-vw * this.currentIndex, ease:"power2.inOut"});
			
		}
	}

}
</script>

<style scoped lang="less">
.homeview{
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;

	.boxes {
		display: flex;
		flex-direction: column;
		position: relative;

		.list {
			display: flex;
			flex-direction: row;
			.box {
				width: 100%;
				min-width: 100%;
			}
		}

		.navigation {
			.center();
			top: 45%;
			// overflow: hidden;
			position: absolute;
			pointer-events: none;
			width: calc(100% - 2em);
			max-width: 1200px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			z-index: 1;

			// &.noPrev {
				// justify-content: flex-end;
			// }

			button {
				background: none;
				transition: all 1s;
				padding: 2em;
				pointer-events: all;
				img {
					width: 2em;
				}
				&.prev {
					&:hover {
						margin-left: -1em;
						padding-right: 3em;
					}
				}
				&.next {
					&:hover {
						margin-right: -1em;
						padding-left: 3em;
					}
				}
			}
		}
	}

}
@media only screen and (max-width: 480px) {
.homeview{
	.boxes {
		.navigation {
			bottom: -2em;
			position: unset;
			transform: unset;
			top: unset;
			width: 70%;
			margin: auto;

			&.noPrev {
				justify-content: space-between;
			}
			
			button {
				padding: .5em 2em;
				img {
					width: 1.5em;
				}

				&.prev:hover,
				&.next:hover {
					margin-right: unset;
					margin-left: unset;
				}
			}
		}
	}
}
}
</style>