<template>
	<div :class="classes">
		<div class="noise"></div>
		<div class="noise noise2"></div>
		
		<transition name="transition-fade">
			<router-link :to="{name:'home'}" class="homeBt" v-if="showHomeBt">- HOME -</router-link>
		</transition>
		
		<router-view class="pageContent" />

		<Button :icon="getIcon('fullscreen')" class="fsBt" @click="toggleFullscreen()" />

		<!-- <router-view v-slot="{ Component }"> -->
			<!-- Use any custom transition and fallback to `fade` -->
			<!-- <transition
			@enter="onEnter"
			@leave="onLeave">
				<component :is="Component" class="pageContent" ref="view" />
			</transition>
		</router-view> -->

		<PictureViewer />
	</div>
</template>

<script lang="ts">
import { watch } from '@vue/runtime-core';
import { Options, Vue } from 'vue-class-component';
import Button from './components/Button.vue';
import { componentsStore } from './stores/components';
import type { ITransitionnableView } from "./utils/ITransitionnableView";
import PictureViewer from './views/PictureViewer.vue';

@Options({
	props:{},
	components:{
		Button,
		PictureViewer,
	}
})
export default class App extends Vue {

	private noScroll:boolean = false;
	private currentView!:ITransitionnableView;

	public getIcon(name:string):string { return new URL(`./assets/icons/${name}.svg`, import.meta.url).href; }

	public get classes():string[] {
		let res = ["app"];
		if(this.noScroll) res.push("noScroll");
		return res;
	}

	public get showHomeBt():boolean {
		return this.$router.currentRoute.value.name != "home";
	}

	public async onEnter(el:Vue, done:()=>void):Promise<void> {
		const view = this.$refs.view as ITransitionnableView;
		this.currentView = view;
		console.log("enter", view);
		if(view?.transitionOpen) {
			view.transitionOpen(done);
		}else{
			done();
		}
	}
	public async onLeave(el:Vue, done:()=>void):Promise<void> {
		const view = this.currentView;
		console.log("leave", view);
		if(view?.transitionLeave) {
			view.transitionLeave(done);
		}else{
			done();
		}
	}

	public mounted():void {
		watch(()=>componentsStore().gallery, async ()=>{
			this.noScroll = componentsStore().gallery?.length > 0
		});
	}

	public toggleFullscreen():void {
		const elem = document.body;
		const isFS = document.fullscreenElement != null;
		if (elem.requestFullscreen) {
			if(isFS) {
				document.exitFullscreen();
			}else{
				elem.requestFullscreen();
			}
		//@ts-ignore
		} else if (elem.webkitRequestFullscreen) { /* Safari */
			if(isFS) {
				//@ts-ignore
				document.webkitExitFullscreen();
			}else{
				//@ts-ignore
				elem.webkitRequestFullscreen();
			}
		//@ts-ignore
		} else if (elem.msRequestFullscreen) { /* IE11 */
			if(isFS) {
				//@ts-ignore
				document.msExitFullscreen();
			}else{
				//@ts-ignore
				elem.msRequestFullscreen();
			}
		}
	}

}
</script>

<style scoped lang="less">
.app{
	// padding: 1em;
	// width: 100vw;
	// height: 100vh;
	// overflow-y: auto;
	// background: rgba(0, 0, 0, 0);
	// background: red;
	min-height: 100vh;

	&.noScroll {
		overflow: hidden;
		height: 100vh;
	}

	.fsBt {
		position: absolute;
		top: 0;
		right: 0;
		background: transparent;
		z-index: 1;
		:deep(.icon) {
			max-height: unset;
		}
		&:hover {
			background-color: rgba(255, 255, 255, .1);
		}
	}

	.noise {
		position: fixed;
		top:0;
		left:0;
		bottom: 0;
		right: 0;
		background-image: url("@/assets/images/noise2.png");
		animation: fade 15s infinite linear;
		&.noise2 {
			background-position: 250px 250px;
			animation: fade2 10s infinite linear;
		}
		@keyframes fade {
			0% {opacity: 1;}
			50% {opacity: .5;}
			100% {opacity: 1;}
		}
		@keyframes fade2 {
			0% {opacity: .2; }
			50% {opacity: .5; }
			100% {opacity: .2; }
		}
	}

	.homeBt {
		padding-top: 1em;
		position: absolute;
		left:50%;
		transform: translate(-50%, 0);
		display: block;
		z-index: 2;
		text-decoration: none;
		color: @mainColor_normal;
		font-size: .8em;
	}

	.pageContent {
		min-height: 100vh;
		position: relative;
		margin: auto;
		z-index: 1;
	}
}
</style>